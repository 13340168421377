body
  margin: 0
  padding : 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
.jss2
    width: 100% 
    height: 100% 
    display: flex 
    margin-top: 8px
    flex-direction: row !important
    justify-content: space-around 
svg
  cursor: pointer
 
.filter-header
    width: 95%
    margin: 0 auto
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
        padding: 0