.MuiPagination-ul
    margin: 0
    display: flex
    padding: 0
    flex-wrap: inherit
    list-style: none
    align-items: center
.data-table
    svg
        cursor: pointer
    .MuiTableCell-root
        padding: 1px 1px!important
    th,td
        font-size: 13px!important
        min-width:8rem !important
.css-jhdc2n-MuiGrid-root 
   width: calc(98% + 16px) !important
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
    padding: 8px 14px !important
.css-1x5jdmq
    padding: 8px 14px !important
.css-jhdc2n-MuiGrid-root>.MuiGrid-item
    padding-top: 13px !important

